* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0 auto;
    font-family: "Cormorant", sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #000;
    background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

h1 {
    font-family: "Cormorant", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
}

h2 {
    font-family: "Cormorant", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.6px;
}

h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

h4 {
    font-family: "Cormorant", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 118.182% */
    text-transform: uppercase;
}

h5 {
    font-family: "Cormorant", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

h6 {
    font-family: "Cormorant", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
}

.container {
    padding: 0 30px;
    margin: 0 auto;
    max-width: 1360px;
}


/* header */
header {
    position: relative;
    padding: 45px 0 60px;
}

.logo {
    display: block;
    margin-bottom: 60px;
    text-align: center;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav a {
    color: #000;
    font-family: Cormorant;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.nav .active a {
    color: #7f7f7f;
}

.nav a:hover,
.nav a:active {
    color: #7f7f7f;
}


.menu,
#burger {
    display: none;
}

/* about */
.about {
    padding-bottom: 80px;
}

.aboutImg {
    display: block;
    text-align: center;
    margin-bottom: 70px;
}

.about h3 {
    margin-bottom: 40px;
}

p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.aboutText {
    margin-bottom: 40px;
}

/* footer */
.footer {
    padding-bottom: 70px;
}

.footerItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer p {
    color: #7f7f7f;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer h6 {
    margin-bottom: 20px;
}

.footer .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contactsTitle {
    margin-top: 70px;
}

.contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contacts p,
a {
    color: #7f7f7f;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contacts .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.socialItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 22px;
}

/* price */
.price {
    padding-bottom: 45px;
}

.priceText {
    text-align: center;
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    margin-bottom: 70px;
}

.serviceItems {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
}

.serviceItem {
    width: 272px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.serviceItem h2 {
    text-align: center;
    margin-bottom: 20px;
}

.serviceItem p {
    padding: 0 59px 20px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    border-bottom: 1px solid #000;
    height: 179px;
}

.serviceItem b {
    display: block;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.calculate {
    text-align: center;
    margin-bottom: 70px;
}

.calculate a {
    background-color: #404652;
    color: #fff;
    padding: 14px 33px;
    font-family: "Cormorant", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.calculateItem {
    position: relative;
    padding-bottom: 60px;
    border-left: 1px solid #000000;
    padding-left: 23px;
    margin-left: 23px;
}

.calculateItem:last-child {
    border: none;
}

.calculateItem div {
    position: absolute;
    left: -23px;
    top: -10px;
    border: 1px solid #000000;
    height: 46px;
    width: 46px;
    border-radius: 50px;
    text-align: center;
    line-height: 52px;
    background: #fff;
}

.calculateItem h4 {
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 25px;
}

.calculateItem p {
    margin-left: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
}

/* events */
.events {
    padding-bottom: 90px;
}

.eventItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 10px;
}

.eventItem {
    margin-bottom: 45px;
    cursor: pointer;
}

.eventItem div {
    margin-bottom: 20px;
}

.eventItem h2 {
    text-align: center;
}

.post {
    padding-bottom: 90px;
}

.postItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.postItem {
    margin-bottom: 20px;
}

/* season */
.season {
    padding-bottom: 90px;
    text-align: center;
}

.season p {
    padding: 0 120px;
}

.seasonItem h2 {
    margin: 50px 0 20px;
}

.seasonItem p {
    color: #7f7f7f;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 25px;
}

.seasonImg {
    display: flex;
    gap: 10px;
    justify-content: center;
}

/* reviews */
.reviews {
    text-align: center;
    padding-bottom: 90px;
}

.reviewsItems {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.reviewsItemImg img{
    max-width: 420px;
    width: 100%;
    max-height: 420px;
    object-fit: cover;
}

.reviewsItem h2 {
    margin: 20px 0 35px;
}

.reviewsItem {
    position: relative;
}

.reviewsItem p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    padding: 0 60px;
    position: relative;
}

.quoteOpen {
    position: absolute;
    left: 10px;
}

.quoteClose {
    position: absolute;
    right: 10px;
    bottom: 0px;
}


/* form */
.form {
    padding-bottom: 90px;
    text-align: center;
}

.formWrap {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.formInputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 50px;
}

.formWrap input {
    width: 100%;
    height: 55px;
    padding: 14px;
}

textarea {
    width: 100%;
    height: 280px;
    padding: 20px;
}

textarea::placeholder,
.formWrap input::placeholder {
    font-family: "Cormorant", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.form button[type="submit"] {
    padding: 14px 33px;
    background: #404652;
    color: #fff;
    font-family: "Cormorant", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
}


/* slider */
.mySwiperPrice {
    margin-bottom: 40px;
    padding-bottom: 60px !important;
}

.serviceItemSlider {
    width: 272px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.serviceItemSlider h2 {
    text-align: center;
    margin-bottom: 20px;
}

.serviceItemSlider p {
    padding: 0 59px 20px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    border-bottom: 1px solid #000;
    height: 179px;
}

.serviceItemSlider b {
    display: block;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.swiper-pagination-bullet-active {
    background: #404652 !important;
}

/* 1350px */
@media (max-width: 1350px) {
    .eventItem div {
        max-width: 336px;
        max-height: 409px;
    }

    .eventItem h2 {
        font-size: 20px;
    }

    .seasonImgItem {
        max-width: 336px;
        max-height: 336px;
    }

    .season p {
        padding: 0 40px;
    }

    .reviewsItem {
        max-width: 336px;
    }

    .reviewsItem p {
        padding: 0 40px;
    }
}

/* 1200px */
@media (max-width: 1200px) {
    h2 {
        font-size: 18px;
        line-height: 21.8px;
        letter-spacing: 1.7px;
    }

    .serviceItems {
        margin-bottom: 55px;
    }

    .serviceItems p {
        padding: 0 42px 10px;
        font-size: 9px;
        line-height: 18px;
        height: 120px;
    }

    .serviceItems b {
        font-size: 14px;
        line-height: 17.07px;
    }

    .serviceItem {
        max-width: 186px;
    }

    .calculate {
        margin-bottom: 55px;
    }
}

/* 1100px */
@media (max-width: 1100px) {
    header {
        padding: 45px 0 45px;
    }

    .logo {
        margin: 0 auto 45px;
        max-width: 235px;
        height: 77px;
    }

    .logo img{
        max-width: 235px;
        width: 100%;
    }

    .nav a {
        font-size: 14px;
        line-height: 12.11px;
        letter-spacing: 1px;
    }

    .aboutImg {
        margin-bottom: 50px;
    }

    h1 {
        font-size: 25px;
        line-height: 30.28px;
        letter-spacing: 2.5px;
        margin-bottom: 40px;
    }

    h3 {
        font-size: 14px;
        line-height: 22px;
    }

    p {
        font-size: 13px;
        line-height: 22px;
    }

    h6 {
        font-size: 14px;
        line-height: 16.95px;
        letter-spacing: 1.4px;
    }

    .footer a {
        font-size: 10px;
        line-height: 12.19px;
    }

    h4 {
        font-size: 19px;
    }

    .calculateItem p {
        font-size: 12px;
        line-height: 20px;
    }

    .calculateItem {
        padding-bottom: 40px;
    }

    .eventItem div {
        max-width: 253px;
        max-height: 307px;
    }

    .eventItem h2 {
        font-size: 18px;
    }

    .seasonItem h2 {
        margin: 35px 0 15px;
    }

    .season p {
        margin-bottom: 20px;
    }

    .seasonImgItem {
        max-width: 253px;
        max-height: 253px;
    }

    .eventItem div {
        max-width: 294px;
        max-height: 358px;
    }

    .seasonImgItem {
        max-width: 294px;
        max-height: 294px;
    }

    .reviewsItem {
        max-width: 253px;
    }

    .reviewsItem p {
        padding: 0 40px;
        font-size: 10px;
        line-height: 16px;
    }
}

/* 1000px */
@media (max-width: 1000px) {
    /* header {
      padding: 45px 0 45px;
    }
    .logo {
      margin: 0 auto 45px;
      width: 235px;
      height: 77px;
    }
    .nav a {
      font-size: 10px;
      line-height: 12.11px;
      letter-spacing: 1px;
    }
    .aboutImg {
      margin-bottom: 50px;
    }
    h1 {
      font-size: 25px;
      line-height: 30.28px;
      letter-spacing: 2.5px;
      margin-bottom: 40px;
    }
    h3 {
      font-size: 14px;
      line-height: 22px;
    }
    p {
      font-size: 13px;
      line-height: 22px;
    }

    h6 {
      font-size: 14px;
      line-height: 16.95px;
      letter-spacing: 1.4px;
    }
    .footer a {
      font-size: 10px;
      line-height: 12.19px;
    }
    h4 {
      font-size: 19px;
    }*/
    .calculateItem p {
        font-size: 12px;
        line-height: 20px;
    }

    .calculateItem {
        padding-bottom: 40px;
    }

    .eventItem div {
        max-width: 253px;
        max-height: 307px;
    }

    .eventItem h2 {
        font-size: 18px;
    }

    .seasonItem h2 {
        margin: 35px 0 15px;
    }

    .season p {
        margin-bottom: 20px;
    }

    .seasonImgItem {
        max-width: 253px;
        max-height: 253px;
    }

    .reviews {
        padding-bottom: 70px;
    }
}

/* 850px */
@media (max-width: 850px) {
    .eventItem div {
        max-width: 220px;
        max-height: 263px;
    }

    .seasonImgItem {
        max-width: 220px;
        max-height: 220px;
    }

    .reviewsItems {
        flex-wrap: wrap;
    }

    .reviewsItem {
        max-width: 220px;
    }

    .reviewsItem p {
        padding: 0 30px;
    }

}

/* 750px */
@media (max-width: 750px) {
    .logo {
        margin: 0 0 40px;
        width: 220px;
        height: 72px;
    }

    .nav {
        display: none;
    }

    .menu,
    .menu::before,
    .menu::after {
        display: block;
        background-color: #000;
        width: 30px;
        height: 4px;
        position: absolute;
        right: 30px;
        top: 50px;
        z-index: 10;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;
    }

    .menu::before {
        content: "";
        top: -10px;
        right: 0;
    }

    .menu::after {
        content: "";
        right: 0;
        top: 10px;
    }

    #burger:checked + .menu::before {
        top: 0;
        transform: rotate(405deg);
    }

    #burger:checked + .menu {
        background-color: #fff;
    }

    #burger:checked + .menu::after {
        top: 0;
        transform: rotate(-405deg);
    }

    #burger:checked + label + nav {
        overflow-y: scroll;
        text-align: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: fixed;
        z-index: 9;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100dvh;
        padding: 30vh 0 30vh;
    }

    header {
        padding: 30px 0 0;
    }

    .aboutImg {
        margin-bottom: 40px;
    }

    h1 {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 24px;
        line-height: 29.06px;
    }

    .about h3 {
        margin-bottom: 25px;
    }

    .aboutText {
        margin-bottom: 25px;
    }

    .about {
        padding-bottom: 40px;
    }

    .socialItems {
        gap: 10px;
    }

    .eventItem div {
        max-width: 180px;
        max-height: 220px;
    }

    .eventItem h2 {
        font-size: 16px;
    }

    .seasonImgItem {
        max-width: 180px;
        max-height: 180px;
    }

    .reviewsItems {
        justify-content: center;
        row-gap: 40px;
    }

    .reviewsItem {
        max-width: 420px;
    }

    .reviewsItem p {
        padding: 0 30px;
    }

    .reviewsItem h2 {
        font-size: 20px;
        line-height: 24.22px;
        letter-spacing: 2px;
    }

    .reviewsItem p {
        font-size: 11px;
        line-height: 22px;
    }

    .formWrap {
        display: flex;
        flex-direction: column;
    }

    .formButtom {
        margin-top: 30px;
    }

    .form a {
        padding: 14px 33px;
        font-size: 14px;
        line-height: 19.38px;
    }
}

/* 660px */
@media (max-width: 660px) {
    .mySwiper {
        margin-bottom: -20px;
        padding-bottom: 40px !important;
    }

    .eventItem div {
        max-width: 253px;
        max-height: 307px;
    }

    .seasonImgItem {
        max-width: 100%;
        max-height: 100%;
    }
}

/* 580px */
@media (max-width: 580px) {
    .eventItem div {
        max-width: 210px;
        max-height: 256px;
    }
}

/* 500px */
@media (max-width: 500px) {
    .logo {
        width: 110px;
        height: 36px;
        margin-bottom: 30px;
    }

    .menu {
        top: 40px;
    }

    .menu,
    .menu::before,
    .menu::after {
        width: 25px;
        height: 3px;
    }

    .footerItems {
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }

    .socialItems {
        justify-content: center;
    }

    .socialImg {
        width: 17px;
        height: 17px;
    }

    .footer h6 {
        margin-bottom: 10px;
    }

    .contacts p,
    a {
        font-size: 11px;
    }

    .socialImg {
        width: 17px;
        height: 17px;
    }

    .eventItem div {
        width: 160px;
        height: 195px;
    }

    .eventItem h2 {
        font-size: 14px;
        line-height: 16.95px;
        letter-spacing: 1px;
    }
}

/* 450px */
/* @media (max-width: 450px) {
  .logo {
    width: 110px;
    height: 36px;
    margin-bottom: 30px;
  }
  .menu {
    top: 40px;
  }
  .menu,
  .menu::before,
  .menu::after {
    width: 25px;
    height: 3px;
  }
  .footerItems {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
  .socialItems {
    justify-content: center;
  }
  .socialImg {
    width: 17px;
    height: 17px;
  }
  .footer h6 {
    margin-bottom: 10px;
  }

  .contacts p,
  a {
    font-size: 11px;
  }
  .socialImg {
    width: 17px;
    height: 17px;
  }
} */
